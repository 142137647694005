/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

body {
  margin: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100vh;

  app-url-verification {
    display: none;
  }

  div:has(app-image-map) {
    display: none;
  }
}
